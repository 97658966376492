/* Font Family Start */
@font-face {
    font-family: myFirstFont500;
    src: url(../Fonts/Karla-Medium.ttf);
}

@font-face {
    font-family: myFirstFont700;
    src: url(../Fonts/Karla-Bold.ttf);
}

@font-face {
    font-family: lato400;
    src: url(../Fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato500;
    src: url(../Fonts/Lato-Medium.ttf);
}

@font-face {
    font-family: lato700;
    src: url(../Fonts/Lato-Bold.ttf);
}


.contactBigTitle {
    font-family: 'lato700';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    /* identical to box height */

    margin-top: 92px;
    color: #242424;
}

.floatingNav {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.emailStyleSend:hover {
    text-decoration-line: underline;

    color: #363DF1 !important;
}

.contactSmallTitle {
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height, or 144% */


    color: #242424;

    opacity: 0.7;
    margin-top: 13px;
}

.contactCallSectionStyle {
    margin-top: 62px;
}

.callBigTextStyle {
    font-family: 'lato700';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    /* identical to box height, or 92% */

    margin-top: 36px;
    color: #242424;

}

.callSmallTextStyle {
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 22px;
    /* identical to box height, or 105% */
    margin-top: 16px;

    color: #242424;
}

iframe {
    width: 100%;
    height: 544px;
    border: 0;
    margin-top: 68px;
    margin-bottom: 62px;
}

@media screen and (max-width: 480px) {
    .contactCallSectionStyle {
        margin-left: 20%;
    }

    iframe {
        height: 350px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}