/* Font Family Start */
@font-face {
    font-family: myFirstFont500;
    src: url(../Fonts/Karla-Medium.ttf);
}

@font-face {
    font-family: karla600;
    src: url(../Fonts/Karla-SemiBold.ttf);
}

@font-face {
    font-family: myFirstFont700;
    src: url(../Fonts/Karla-Bold.ttf);
}

@font-face {
    font-family: lato400;
    src: url(../Fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato500;
    src: url(../Fonts/Lato-Medium.ttf);
}

@font-face {
    font-family: lato700;
    src: url(../Fonts/Lato-Bold.ttf);
}


/* Font Family Finished */
/* ///////////////////////////////////////////////////////////////////////////// */
/* Home strat */
.home-container {
    width: 100%;
    height: 100%;
}

.homeWrapper {
    width: 100%;
    height: 664px;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/homeBgImg.svg?alt=media&token=fb11d3b5-4845-4f36-98ec-9ad53c8d2ed1');
    background-size: cover;
}

.homeBasicTextStyle {
    font-family: 'karla600';
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    line-height: 92px;
    /* or 170% */

    letter-spacing: 0.06em;

    color: #FFFFFF;

    opacity: 0.92;

    padding-top: 252px;
    padding-left: -30px !important;
    padding-bottom: 120px;
}

.homeBottomBigText {
    margin-top: 40px;
    font-family: 'myFirstFont700';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 58px;
    letter-spacing: -0.02em;
    color: #242424;
}

.homeBottomSmallText {
    font-family: 'lato400';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    color: #242424;
    margin-bottom: 44px;
    opacity: 0.9;
}

.ourPorojectBigText {
    padding-top: 20px;
    border-top: 6px solid #363DF1;
    border-radius: 1px;
    width: 162px;
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #242424;
    opacity: 0.75;
}

.oirProjextImgStyle {
    width: 100%;
    height: 388px;
    margin-top: 77px;
}


.ourProjextImgStyle {
    width: 100%;
    margin-top: 456px;
    margin-top: 62px;
}

.ourProjextImgStyle1 {
    width: 100%;
    margin-top: 355px;
    margin-top: 130px;
}

.ourProjectBigText {
    margin-top: 62px;
    margin-left: 20px;
    font-family: 'lato700';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #000000;
}

.ourProjectSmallText {
    margin-top: 24px;
    margin-left: 20px;
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 63px;
    color: #000000;
    opacity: 0.9;
}

.hrStyleHOme {
    margin-top: 78px;
    height: 2px;
    background: #0B1B7E;
    opacity: 0.15;
}

.sectionWrapper {
    background: #F7F8FA;
    margin-bottom: 59px;
}

.sectionImgStyle {
    margin-top: 80px;
    width: 100%;
    height: 930px;
    background-size: cover !important;
    margin-bottom: 66px;
}

.ourPartners {
    width: 100%;
    height: 120px;
    background: #FCFCFC;
    border-top: 2px solid #e2dbdb;
    padding-top: 1%;
    padding-bottom: 7% !important;
}

/* Home Finished */
/* ///////////////////////////////////////////////////////////////////////////// */
/* Media Query Start */
@media screen and (max-width: 480px) {
    .homeWrapper {
        height: auto;
    }

    .homeBottomBigText {
        font-size: 21px;
    }

    .homeBottomSmallText {
        font-size: 18px;
        margin-top: -15px !important;
        margin-top: -20px !important;
    }

    .ourProjectSmallText {
        font-size: 18px;
    }

    .ourProjextImgStyle1 {
        display: none;
    }

    .oirProjextImgStyle {
        width: 100%;
        height: auto;
    }

    .ourProjextImgStyle {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    .sectionImgStyle {
        height: auto;
    }

    .ourPartners {
        height: auto;
    }

    /* .ourProjextImgStyle1 {
        width: 100%;
        height: auto;
        margin-top: 20;
    } */
}