/* Font Family Start */
@font-face {
    font-family: myFirstFont500;
    src: url(../Fonts/Karla-Medium.ttf);
}

@font-face {
    font-family: myFirstFont700;
    src: url(../Fonts/Karla-Bold.ttf);
}

@font-face {
    font-family: lato600;
    src: url(../Fonts/Karla-SemiBold.ttf);
}


@font-face {
    font-family: lato400;
    src: url(../Fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato500;
    src: url(../Fonts/Lato-Medium.ttf);
}

@font-face {
    font-family: lato700;
    src: url(../Fonts/Lato-Bold.ttf);
}


.read-or-hide {
    font-family: 'lato400';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    cursor: pointer;
    /* or 178% */


    color: #127FFF;
}

.servicesTitle {
    padding-top: 20px;
    margin-top: 80px;
    margin-bottom: 62px;
    border-top: 6px solid #363DF1;
    border-radius: 1px;
    width: 162px;
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    opacity: 0.75;
    line-height: 22px;
    color: #242424;
}

.serviceTopStyle {
    margin-top: 80px;
}

.serviceBigText {
    font-family: 'lato600';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;

    color: #242424;
    margin-top: 71px;
}

.serviceSmallText {
    font-family: 'lato400';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    /* or 152% */
    margin-bottom: 45px;


    color: #242424;

    opacity: 0.8;
}

.servicesTxts {
    margin-left: -20%;
}

.gridBorderAll1 {
    border-right: 1px solid #C5C7D6;
    border-bottom: 1px solid #C5C7D6;
}

.gridBorderAll2 {
    border-bottom: 1px solid #C5C7D6;
}

.gridBorderAll3 {
    border-right: 1px solid #C5C7D6;
    border-bottom: 1px solid #C5C7D6;
}

.gridBorderAll4 {
    border-right: 1px solid #C5C7D6;
    margin-bottom: 64px;
}

.col-sm-12 .imgLefetStyleService {
    margin-top: 80px;
}

.col-sm-12 .imgLefetStyleService1 {
    margin-top: 80px;
}

.imgLefetStyleService1 {
    margin-left: 66px;
}

.flexContainerSerive {
    display: flex;
}

.servicesTxts {
    margin-left: 56px;
}


@media screen and (max-width: 480px) {
    .servicesTxts {
        margin-left: unset;
        padding: 20px;
    }

    .flexContainerSerive {
        flex-direction: column;
    }

    .gridBorderAll4 {
        border: none;
    }

    .gridBorderAll3 {
        border: none;
    }

    .gridBorderAll2 {
        border: none;
    }

    .gridBorderAll1 {
        border: none;
    }

    .imgLefetStyleService {
        margin-left: unset;
    }

    .serviceTopStyle img {
        margin-left: 30%;
    }
}