.slick-track {
    margin-top: 6%;
}

.slick-prev {
    width: 53px;
    height: 53px;
    margin-top: 2.8%;
    border: 2px solid #037B35 !important;
    box-sizing: border-box !important;
    border-radius: 50%;
}

.slick-next {
    width: 53px;
    height: 53px;
    margin-top: 2.8%;
    border: 2px solid #037B35 !important;
    box-sizing: border-box !important;
    border-radius: 50%;
    /* margin-left: 05% !important; */
}

.slick-prev:before {
    color: #037B35;
    opacity: unset;
}

.slick-next:before {
    color: #037B35;
    opacity: unset;
}

.slick-slide img {
    margin-top: -36%;
}

.partnerNameStyle {
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    position: absolute;
    top: -103px;

    color: #242424;

    opacity: 0.75;
}

.slashStyle {
    width: 2px;
    margin-left: 71px;
    font-size: 171px;
    border-right: 2px solid #242424;
    opacity: 0.1;
}

.cardImgesCarousel1 {
    margin-top: -43% !important;
}

.cardImgesCarousel2 {
    margin-top: -37% !important;
}

/* Start Media Query */
@media screen and (max-width: 480px) {
    .slick-slide img {
        margin-left: 27% !important;
        margin-top: 14%;
    }

    .cardImgesCarousel1 {
        margin-top: unset !important;
    }

    .cardImgesCarousel2 {
        margin-top: unset !important;
    }

    .cardCarouselBackgrondImage {
        padding: 20px;
    }

    .partnerNameStyle {
        position: relative;
        margin-left: 27%;
        margin-top: 49%;
    }

    .slashStyle {
        display: none;
    }
}

@media (min-width: 820px) and (max-width: 1180px) {
    .slick-next {
        margin-top: -1.2%;
    }

    .slick-prev {
        margin-top: -1.2%;
    }
}

@media (min-width: 540px) and (max-width: 720px) {
    .slick-next {
        margin-top: -2.2%;
        left: 90%;
    }

    .slick-prev {
        margin-top: -2.2%;
    }
}