/* Font Family Start */
@font-face {
    font-family: myFirstFont500;
    src: url(../Fonts/Karla-Medium.ttf);
}

@font-face {
    font-family: myFirstFont700;
    src: url(../Fonts/Karla-Bold.ttf);
}

@font-face {
    font-family: lato400;
    src: url(../Fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato500;
    src: url(../Fonts/Lato-Medium.ttf);
}

@font-face {
    font-family: lato600;
    src: url(../Fonts/Lato-Semibold.ttf);
}
@font-face {
    font-family: lato700;
    src: url(../Fonts/Lato-Bold.ttf);
}

@font-face {
    font-family: lato600;
    src: url(../Fonts/Lato-Semibold.ttf);
}

@font-face {
    font-family: Roboto500;
    src: url(../Fonts/Roboto-Medium.ttf);
}


/* .Root {
    height: 100vh;
} */

.Tabs {
    display: flex;
    margin-top: 102px;
    margin-left: -8%;
    margin-right: -3%;
}

/* .buttonSrtleDivInApply
{
    padding-bottom: 20%;
} */

.TabList {
    width: 34%;
    color: black !important;
}

.TabList li {
    list-style: none;
}

.TabPanel {
    flex: 1;
    display: none;
}

.TabPanel.is-active {
    display: block !important;
}

.insideFrame {
    height: 440px;
    border: 1px solid rgba(30, 47, 151, 0.2);
    border-radius: 2px;
    padding: 1.5rem 2rem;
    margin-left: 29px;
}




.BasicTextColor {
    cursor: pointer;
    margin-top: 36px;
    font-family: 'lato600';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;


    color: #242424;

    opacity: 0.8;
}

.tabbarBasic1 {
    color: #1E2F97;
    margin-left: 15%;
}

.tabbarBasic2 {
    color: #242424;
    margin-left: 15%;
}

.serviceButtonStyle {
    margin-left: 89.5%;
    font-family: 'lato600';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 62px;
    margin-top: 42px;

    color: #FFFFFF;
    background: #1E2F97;
    border-radius: 2px;
    width: 110px;
    height: 42px;
    border: none;
}

.applyBtnMtAndMb {
    margin-left: 65%;
    margin-top: 72px;
    margin-bottom: 48px;
}

.uploadCVApplyPageBtn {
    /* margin-left: 86.5%; */
    font-family: 'lato600';
    margin-left: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;
    background: #1E2F97;
    border-radius: 2px;
    width: 110px;
    height: 42px;
    border: none;
}

.uploadCVApplyPageBtn2 {
    font-family: 'lato600';
    margin-left: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
    background: #F8F8FF;
    border: 1px solid rgba(36, 36, 36, 0.05);
    width: 110px;
    height: 42px;
    border: none;
}

.serviceButtonStyle2 {
    color: #FFFFFF;
    background: #1E2F97;
    border-radius: 2px;
    width: 110px;
    height: 42px;
    border: none;
    margin-top: -84p !important;
    margin-bottom: 10px;
}

.applyFirstBigText {
    font-family: 'lato700';
    margin-left: 32px;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;

    color: #242424;
}

.applySecondSmallText {
    font-family: 'lato500';
    margin-left: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */


    color: #242424;

    opacity: 0.8;
}

.tabPanelStyle {
    margin-bottom: 140px !important;
}

.applySelectedStyle {
    border: 1px solid rgba(47, 46, 46, 0.2);
    border-radius: 2px;
    width: 350px;
    height: 48px;
    padding: 10px;
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: rgba(34, 34, 34, 0.6);
}

.selectTitleInApply {
    font-family: 'lato600';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
    margin-top: 42px;
    margin-bottom: 10px;
}

.tabNumberStyle {
    font-family: 'Roboto500';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
}

.applyInputStyle {
    width: 350px;
    height: 47px;
    border: 1px solid rgba(47, 46, 46, 0.2);
    border-radius: 2px;
    padding: 12px;
}

.applyInputStyle:focus {
    outline: none;
    border: 1px solid #1E2F97;
    color: #1E2F97;
}

.selectTitleInApply1 {
    margin-top: 36%;
}

.applyRadioStyle {
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: rgba(36, 36, 36, 0.6);
}

.applyRadioStyle:active {
    color: #1E2F97 !important;
}

.applyRadioStyle:focus {
    color: #1E2F97 !important;
}

.allRadiosStyleLaptopApply2 {
    display: none;
}

.tabpanel3Apply {
    width: 89%;
}

.tab_bar_block {
    display: block;
    width: 89%;
}

.tab_bar_none {
    display: none;
}


@media screen and (max-width: 480px) {
    .Tabs {
        flex-direction: column;
    }

    .TabList {
        width: 100%;
    }

    .insideFrame {
        height: auto;
    }

    .TabPanel {
        margin-right: 2%;
        margin-left: 2%;
    }

    .serviceButtonStyle {
        margin-left: 70%;
        margin-bottom: 42px;
    }

    .applyBtnMtAndMb {
        display: flex !important;
        margin-left: 30%;
    }

    .applySelectedStyle {
        width: 100%;
    }

    .applyInputStyle {
        width: 100%;
    }

    .selectTitleInApply1 {
        margin-top: 12%;
    }

    .allRadiosStyleLaptopApply {
        display: none;
    }

    .allRadiosStyleLaptopApply2 {
        display: block;
    }
}

@media (min-width: 540px) and (max-width: 720px) {
    .insideFrame {
        height: auto;
        flex-direction: column;
    }

    .Tabs {
        flex-direction: column;
    }

    br {
        display: block !important;
    }

    .serviceButtonStyle {
        margin-left: 60%;

    }
}

@media (min-width: 820px) and (max-width: 1180px) {
    .insideFrame {
        height: auto;
        flex-direction: column;
    }

    .Tabs {
        flex-direction: column;
    }

    br {
        display: block !important;
    }

    .serviceButtonStyle {
        margin-left: 60%;

    }
}

@media (max-width: 768px) {
    .insideFrame {
        height: auto;
        flex-direction: column;
    }

    .Tabs {
        flex-direction: column;
    }

    br {
        display: block !important;
    }

    .serviceButtonStyle {
        margin-left: 60%;

    }
}