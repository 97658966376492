/* Font Family Start */
@font-face {
    font-family: myFirstFont;
    src: url(../Fonts/Karla-Medium.ttf);
}

@font-face {
    font-family: karla600;
    src: url(../Fonts/Karla-SemiBold.ttf);
}

/* Font Family Finished */

* {
    margin: 0;
    padding: 0;
}


/* Start Navbar */
.navbarStyleBasic {
    position: fixed;
    top: 0;
    width: 100%;
    position: sticky !important;
    height: 98px;
    background: #FFFFFF;
    color: black;
    z-index: 55;
}

.navbarLogoStye {
    margin-left: -44px !important;
}

.accordion-button:not(.collapsed) {
    background-color: #F7F8FA !important;
}

.accordion-item:first-of-type .accordion-button {
    border: none !important;
    border-radius: unset !important;
}

.navbarPageTextStyle {
    font-family: 'myFirstFont';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-left: 28px;
    /* identical to box height */


    color: #242424 !important;
}

.noBorder {
    margin-bottom: unset;
    border-bottom: none;
    opacity: unset;

    border-radius: unset;
}

.withBorder.navbarPageTextStyle {
    color: #363DF1 !important;
    font-family: karla600;
}

.navbarPageTextStyle:hover {
    color: #363DF1 !important;
    font-family: karla600;
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 30px !important;
}

.navbar-expand-lg .navbar-collapse {
    margin-right: -44px !important;
    z-index: 15;
}

.navbar-nav {
    background-color: unset !important;
}

/* Finished Navbar */


/* Media Query Start */
@media screen and (max-width: 480px) {
    br {
        display: none !important;
    }

    .navbar {
        height: 70px !important;
    }

    .navbar-toggler {
        padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
        font-size: var(--bs-navbar-toggler-font-size);
        line-height: 1;
        color: white !important;
        background-color: black !important;
        border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
        border-radius: var(--bs-navbar-toggler-border-radius);
        transition: var(--bs-navbar-toggler-transition);
        width: 50px !important;
        height: 37px !important;
    }

    .navbar-nav {
        background-color: #F7F8FA !important;
        --bs-nav-link-padding-y: 0.5rem !important;
        margin-left: -15px !important;
        margin-top: 2px !important;
        z-index: 12;
    }

    .navbarLogoStye {
        margin-left: 15px !important;
        width: 56px;
        height: 46px;
    }

    .homeBasicTextStyle {
        font-size: 25px !important;
        line-height: 50px !important;
        padding-top: 12px !important;
        padding-bottom: 15px !important;
    }

    .homeWrapper {
        height: 172px !important;
    }

    .navbarPageTextStyle:focus {
        margin-bottom: unset;
        border-bottom: unset;
        border-radius: unset;
    }

    .navbarPageTextStyle:hover {
        color: unset !important;

        opacity: unset;
    }
}

@media (max-width:768px) {
    br {
        display: none !important;
    }

    .navbar {
        height: 70px !important;
    }

    .navbar-toggler {
        padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
        font-size: var(--bs-navbar-toggler-font-size);
        line-height: 1;
        color: white !important;
        background-color: black !important;
        border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
        border-radius: var(--bs-navbar-toggler-border-radius);
        transition: var(--bs-navbar-toggler-transition);
        width: 50px !important;
        height: 37px !important;
    }

    .navbar-nav {
        background-color: #F7F8FA !important;
        --bs-nav-link-padding-y: 0.5rem !important;
        margin-left: -15px !important;
        margin-top: 2px !important;
        z-index: 12;
    }

    .navbarLogoStye {
        margin-left: 15px !important;
        width: 56px;
        height: 46px;
    }

    .homeBasicTextStyle {
        font-size: 25px !important;
        line-height: 50px !important;
        padding-top: 12px !important;
        padding-bottom: 15px !important;
    }

    .homeWrapper {
        height: 172px !important;
    }

    .navbarPageTextStyle:focus {
        margin-bottom: unset;
        border-bottom: unset;
        border-radius: unset;
    }

    .navbarPageTextStyle:hover {
        color: unset !important;

        opacity: unset;
    }

    .partnerNameStyle {
        margin-left: 3%;
        top: -61px !important;
    }

    .slick-slide img {
        margin-top: -4% !important;
        margin-left: 40% !important;
    }

    .slick-next {
        margin-top: -2.2%;
    }

    .slick-prev {
        margin-top: -2.2%;
    }
}

@media (min-width: 820px) and (max-width: 1180px) {
    br {
        display: none !important;
    }

    .navbar {
        height: 70px !important;
    }

    .navbar-toggler {
        padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
        font-size: var(--bs-navbar-toggler-font-size);
        line-height: 1;
        color: white !important;
        background-color: black !important;
        border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
        border-radius: var(--bs-navbar-toggler-border-radius);
        transition: var(--bs-navbar-toggler-transition);
        width: 50px !important;
        height: 37px !important;
    }

    .navbar-nav {
        background-color: #F7F8FA !important;
        --bs-nav-link-padding-y: 0.5rem !important;
        margin-left: -15px !important;
        margin-top: 2px !important;
        z-index: 12;
    }

    .navbarLogoStye {
        margin-left: 15px !important;
        width: 56px;
        height: 46px;
    }

    .homeBasicTextStyle {
        font-size: 25px !important;
        line-height: 50px !important;
        padding-top: 12px !important;
        padding-bottom: 15px !important;
    }

    .homeWrapper {
        height: 172px !important;
    }

    .navbarPageTextStyle:focus {
        margin-bottom: unset;
        border-bottom: unset;
        border-radius: unset;
    }

    .navbarPageTextStyle:hover {
        color: unset !important;

        opacity: unset;
    }

    .partnerNameStyle {
        margin-left: 3%;
        top: -61px !important;
    }

    .slick-slide img {
        margin-top: -4% !important;
        margin-left: 40% !important;
    }

    .slick-next {
        margin-top: -2.2%;
    }

    .slick-prev {
        margin-top: -2.2%;
    }
}

/* Media Query Finished */