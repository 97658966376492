/* Font Family Start */
@font-face {
    font-family: myFirstFont500;
    src: url(../Fonts/Karla-Medium.ttf);
}

@font-face {
    font-family: myFirstFont700;
    src: url(../Fonts/Karla-Bold.ttf);
}

@font-face {
    font-family: lato400;
    src: url(../Fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato500;
    src: url(../Fonts/Lato-Medium.ttf);
}

@font-face {
    font-family: lato700;
    src: url(../Fonts/Lato-Bold.ttf);
}

@font-face {
    font-family: lato600;
    src: url(../Fonts/Lato-Semibold.ttf);
}





.projectsTitleStyle {
    padding-top: 20px;
    border-top: 6px solid #363DF1;
    border-radius: 1px;
    width: 162px;
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #242424;
    opacity: 0.75;
    margin-top: 80px;
}

.projectbgImgStyle {
    width: 100%;
    height: 600px;
    margin-top: 62px;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/ProjectBgImg.svg?alt=media&token=e10fec25-835b-4540-a100-7db32e745435');
    background-size: cover;
}

.accordion-button::after {
    background-image: url('../Assets/Vector.svg') !important;
}

.projectBgImgTextStylr {
    font-family: 'lato600';
    font-style: normal;
    font-weight: 600;
    font-size: 28px !important;
    line-height: 48px;
    /* or 171% */

    color: #161616;
}

.projectBgImgTextStyle1 {
    font-family: 'lato600';
    font-style: normal;
    font-weight: 600;
    font-size: 28px !important;
    line-height: 48px;
    /* or 171% */
    padding-top: 68px !important;


    color: #161616;
}

.accordionHederStyleInProject {
    padding-top: 62px;
    padding-bottom: 48px;
}

.accordion-button:focus {
    border-color: unset !important;
    outline: unset !important;
    box-shadow: unset !important;
}

.projectAccordingTextStyle {
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    margin-left: 24px;
    font-size: 28px;
    line-height: 64px;
    /* identical to box height, or 229% */


    color: #242424;
}

.projectAccordingInsideTextStyle {
    font-family: 'lato400' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
    /* or 175% */


    color: #000000;
}

.prejectMtStyleNoneImg {
    margin-top: 3%;
}

.prejectMtStyle {
    margin-top: 8%;
}

.projectLatestOpenCleseSectionStyleText {
    font-family: 'lato600' !important;
}

.projectAccordingInsideImgStyle {
    width: 100%;
    height: 528px;
    margin-top: 0% !important;
}

.projectAccordingInsideImgStyle1 {
    width: 100%;
    height: 355px;
    margin-top: 37px !important;
    margin-left: -14px;
}

.accordion-item {
    border-left: unset !important;
    border-right: unset !important;
    border-top-left-radius: #C5C7D6 !important;
    border-top-right-radius: #C5C7D6 !important;
}

.projectDaryoImgStyle {
    margin-top: 0% !important;
    width: 100%;
}

.projectDaryoImgStyleTorino {
    width: 100%;
    height: 433px;
    margin-top: 3%;
}

.projectCivilSocietyStyle {
    width: 100%;
    height: 433px;
    margin-top: 3%;
}

.projectUzTeaImgStyle {
    width: 367px;
    height: 302px;
    margin-top: 0% !important;
    margin-left: 20%;
}

.projectDotsImgStyle {
    margin-top: -1% !important;
    margin-left: -7%;
    margin-right: 2%;
    width: 24px;
    height: 24px;
}

.projectDotsImgStyle2 {
    margin-top: -1% !important;
    margin-left: -3%;
    margin-right: 1%;
    width: 24px;
    height: 24px;
}

.insideDotsTextLeftStyle {
    margin-left: 33px;
}


@media screen and (max-width: 480px) {
    .projectbgImgStyle {
        height: auto;
    }

    .projectAccordingInsideImgStyle {
        height: auto;
    }

    .projectAccordingInsideImgStyle1 {
        height: auto;
    }

    .projectAccordingTextStyle {
        font-size: 14px;
        line-height: 33px;
    }

    .projectUzTeaImgStyle {
        width: 100%;
        height: auto;
        margin-top: 0% !important;
        margin-left: 0%;
    }
}