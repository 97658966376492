@font-face {
    font-family: lato500;
    src: url(../Fonts/Lato-Medium.ttf);
}

@font-face {
    font-family: karla400;
    src: url(../Fonts/Karla-Regular.ttf);
}

.footer-container {
    width: 100%;
    height: auto;
    padding-bottom: 15px;
    background: #0B1B7E;
}

.footerLogoStyle {
    width: 80px;
    height: 66px;
    margin-top: 27px;
    margin-left: 55px;
}

.fotterclmdWIdthStyle {
    width: 43% !important;
    margin-left: 3%;
}

.fotterclmdWIdthStyle2 {
    width: 29% !important;
}

.footerSocialMedia {
    margin-top: 32px;
    margin-left: 20px;
}

.socialMedia {
    margin-left: 51px;
}

.linkForFooter {
    margin-top: 35px;
}

.footerLink {
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;

    color: #FFFFFF;
    margin-left: 30%;
}

.footerHr {
    border: none;
    height: 2px;
    background: rgba(255, 255, 255, 0.178);
}

.footerLatestText {
    font-family: 'karla400';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 22px;
    /* identical to box height, or 105% */
    text-align: center;
    padding-top: 15px;

    color: #FFFFFF;
}

.allFooterLink {
    margin-top: 35px;
}

.footerLinks {
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 105% */


    color: #FFFFFF;

    opacity: 0.9;
}

a {
    text-decoration: none;
}

.fLink {
    margin-top: 32px;
}

.fLink1 {
    margin-top: 32px;
    margin-left: 30%;
}

.fullHDDisableFooter {
    display: none;
}

.footerLogoStyleMobile {
    display: none;
}

.mobileSocialeMediaBloc {
    display: none;
}

.applyFooterLink {
    margin-left: 48px;
}

@media (min-width:21px) and (max-width: 900px) {
    .mobileDisableFooter {
        display: none;
    }

    .fullHDDisableFooter {
        display: block !important;
        width: 50% !important;
    }

    .mlFotterMobileText {
        margin-left: 10%;
    }

    .footerLatestText {
        font-family: 'larla400';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 22px;
        /* identical to box height, or 220% */


        color: #FFFFFF;
    }

    .footerLogoStyleMobile {
        /* width: 56px; */
        height: 46px;
        margin-left: -10%;
    }

    .footerLinkMobile {
        font-family: 'lato500';
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
        /* identical to box height */


        color: #FFFFFF;
    }

    .linkForFooterMobile {
        margin-top: 10px;
    }

    .footerSocialMediaMobile {
        margin-left: 8%;
    }

    .socialMediaMobile {
        margin-left: 30% !important;
    }

    .mobileSocialeMediaBloc {
        display: block;
    }

    .footerLogoStyleMobile {
        display: block;
    }

    .activeBrInMobile {
        display: block !important;
    }
}

@media screen and (max-width: 480px) {
    .mobileDisableFooter {
        display: none;
    }

    .fullHDDisableFooter {
        display: block !important;
        width: 50% !important;
    }

    .mlFotterMobileText {
        margin-left: 10%;
    }

    .footerLatestText {
        font-family: 'larla400';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 22px;
        /* identical to box height, or 220% */


        color: #FFFFFF;
    }

    .footerLogoStyleMobile {
        /* width: 56px; */
        height: 46px;
        margin-left: -10%;
    }

    .footerLinkMobile {
        font-family: 'lato500';
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
        /* identical to box height */


        color: #FFFFFF;
    }

    .linkForFooterMobile {
        margin-top: 10px;
    }

    .footerSocialMediaMobile {
        margin-left: 8%;
    }

    .socialMediaMobile {
        margin-left: 30% !important;
    }

    .mobileSocialeMediaBloc {
        display: block;
    }

    .footerLogoStyleMobile {
        display: block;
    }

    .activeBrInMobile {
        display: block !important;
    }
}