/* Font Family Start */
@font-face {
    font-family: myFirstFont500;
    src: url(../Fonts/Karla-Medium.ttf);
}

@font-face {
    font-family: karla700;
    src: url(../Fonts/Karla-Bold.ttf);
}

@font-face {
    font-family: lato400;
    src: url(../Fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato500;
    src: url(../Fonts/Lato-Medium.ttf);
}

@font-face {
    font-family: lato700;
    src: url(../Fonts/Lato-Bold.ttf);
}

@font-face {
    font-family: lato600;
    src: url(../Fonts/Lato-Semibold.ttf);
}


.expertsWrapper {
    margin-bottom: 62px;
}

.expertsTitleName {
    padding-top: 20px;
    border-top: 6px solid #363DF1;
    margin-bottom: 62px;
    border-radius: 1px;
    width: 162px;
    font-family: 'lato500';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #242424;
    opacity: 0.75;
    margin-top: 92px;
}

.cardExpertsClassNameMT {
    margin-top: 32px;
}

.cardBodyInExpertsPage {
    width: 104%;
    height: 634px;
    background: #F7F8FA;
}

.cardBodyInExpertsPage2 {
    height: 634px;
    background: #F7F8FA;
    /* height: 150px; */
    overflow: auto;
    /* margin: 20px;
    text-align: justify;
    padding: 20px; */
}

.cardInsideImgStyleExperts {
    width: 235px;
    height: 235px;
    margin-left: 22%;
    margin-top: 20px;
    /* margin-bottom: 20px; */
}

.card {
    border: unset !important;
    margin-bottom: 7px !important;
}

.expertsNameTextStyle {
    font-family: 'karla700';
    margin-top: 40px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    font-size: 28px;
    line-height: 45px;
    /* identical to box height, or 161% */

    text-align: center;

    color: #242424;
}

.card-body:hover .expertsNameTextStyle {
    color: #3940F0;
}

.expertsDescriptionTextStyle {
    font-family: 'lato400';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-top: 18px;
    text-align: center;
    color: #242424;
}

.linkedinStyleInExperts {
    width: 48px;
    height: 48px;
    margin-left: 44%;
}

.closeTheCardInExpert {
    margin-top: 20px;
    margin-left: 90%;
    width: 12px;
    height: 12px;
    cursor: pointer !important;
}

.expertsLinkedin {
    position: absolute;
    bottom: 40px !important;
}

.expertsNameTextStyleSecondWay {
    margin-top: 8px;
    font-family: 'karla700';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 45px;
    margin-left: 40px;
    cursor: pointer;
    /* identical to box height, or 161% */


    color: #242424;
}


.expertsDescriptionTextStyleSecondWay {
    font-family: 'lato400';
    margin-left: 40px;
    margin-top: 21px;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 36px;
    /* or 171% */


    color: #242424;
}

@media screen and (max-width: 480px) {
    .cardInsideImgStyleExperts {
        margin-left: 17%;
    }
}

@media (min-width: 820px) and (max-width: 1180px) {
    .cardBodyInExpertsPage {
        height: 700px;

    }

    .col-sm-12 img {
        margin-top: 23px;
    }

    .cardInsideImgStyleExperts {
        width: 195px;
        height: 235px;
        margin-left: 5%;
    }

    .cardBodyInExpertsPage2 {
        height: 700px;
    }

}

@media (min-width: 600px) and (max-width: 1024px) {
    .cardInsideImgStyleExperts {
        margin-left: 16%;
    }
}

@media (max-width: 768px) {
    .cardBodyInExpertsPage {
        height: auto;
    }

    .cardBodyInExpertsPage2 {
        height: 700px;
    }

    .col-sm-12 img {
        margin-top: 23px;
    }

    .cardInsideImgStyleExperts {
        width: 195px;
        height: 235px;
        margin-left: 23%;
    }
}