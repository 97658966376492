/* Font Family Start */
@font-face {
    font-family: myFirstFont500;
    src: url(../Fonts/Karla-Medium.ttf);
}

@font-face {
    font-family: myFirstFont700;
    src: url(../Fonts/Karla-Bold.ttf);
}

@font-face {
    font-family: lato400;
    src: url(../Fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato500;
    src: url(../Fonts/Lato-Medium.ttf);
}

@font-face {
    font-family: lato700;
    src: url(../Fonts/Lato-Bold.ttf);
}

@font-face {
    font-family: lato600;
    src: url(../Fonts/Lato-Semibold.ttf);
}



.files input {
    /* outline: 2px dashed #92b0b3; */
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 189px 0px 85px 35%;
    text-align: center !important;
    margin: -26px;
    margin-left: -36px;
    height: 440px;
    border-radius: unset !important;
    width: 108% !important;
    opacity: 0;
}

.files input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    border: 1px solid #92b0b3;
}

.files {
    position: relative
}

.files:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.color input {
    background-color: #f1f1f1;
}

.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " or drag it here. ";
    display: none;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}

.uploadDragText {
    position: absolute;
    padding-top: 189px;
    padding-left: 289px;
    font-family: 'lato700';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */


    color: #000000;

    opacity: 0.4;
}

.uploadChooseFileStyle {
    position: absolute;
    padding-top: 238px;
    padding-left: 402px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-decoration-line: underline;

    color: #1E2F97;

    opacity: 0.6;
}

@media screen and (max-width: 480px) {
    .files input {
        width: 123% !important;
    }

    .uploadDragText {
        text-align: center;
        padding-left: unset;
    }

    .uploadChooseFileStyle {
        padding-top: 248px;
        padding-left: 100px;
    }

}