/* Font Family Start */
@font-face {
    font-family: myFirstFont500;
    src: url(../Fonts/Karla-Medium.ttf);
}

@font-face {
    font-family: myFirstFont700;
    src: url(../Fonts/Karla-Bold.ttf);
}

@font-face {
    font-family: lato400;
    src: url(../Fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato500;
    src: url(../Fonts/Lato-Medium.ttf);
}

@font-face {
    font-family: lato700;
    src: url(../Fonts/Lato-Bold.ttf);
}

@font-face {
    font-family: lato600;
    src: url(../Fonts/Lato-Semibold.ttf);
}


/* Font Family Finished */



.aboutEnterText {
    padding-top: 20px;
    border-top: 6px solid #363DF1;
    margin-bottom: 42px;
    border-radius: 1px;
    width: 162px;
    font-family: 'lato400';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    opacity: 0.75;
    color: #242424;
    margin-top: 80px;
}

.aboutEnterText2 {
    padding-top: 16px;
    border-top: 6px solid #363DF1;
    margin-bottom: 48px;
    border-radius: 1px;
    width: 162px;
    font-family: 'lato400';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #242424;
    opacity: 0.75;
    margin-top: 104px;
}

.smallTextAbout {
    font-family: 'lato400';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
    /* or 175% */


    color: #161616;

}

.insodeAboutSmallText {
    margin-top: 24px;
}

.aboutImgSection {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/mahorat-management.appspot.com/o/aboutImg.svg?alt=media&token=fd83d3a5-e98f-4691-a058-5cf08a5fe757');
    width: 100%;
    height: 448px;
    background-size: cover;
    margin-top: 42px;
}

.aboutImgSectionText {
    font-family: 'myFirstFont500';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    margin-left: 3%;
    line-height: 46px;
    padding-top: 103px;
    padding-bottom: 60px;
    /* or 144% */


    color: #FFFFFF;
}

.thirdSectionAboutBigText {
    margin-top: 42px;
    font-family: 'lato600';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 42px;
    /* identical to box height, or 175% */


    color: #242424;

}

.SircleImgText {
    font-family: 'lato400';
    margin-top: 24px;
    margin-left: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
    /* or 175% */


    color: #242424;
}

.SircleImgText img {
    margin-left: -35px;
    margin-right: 8px;
}

.howWeWorkAllText {
    font-family: 'lato400';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
    /* or 175% */


    color: #000000;
}

.sectionWithGridAbout {
    margin-top: 48px;
    width: 100%;
    height: auto;
    background: #F7F8FA;
}

.gridSectionInsideStyle {
    padding-top: 56px;
    padding-bottom: 48px;
}

.gridSmallAllText {
    font-family: 'lato400';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    /* or 152% */


    color: #000000;
}

.gridInsieFirstText {
    font-family: 'lato400';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    /* or 152% */


    color: #000000;
}

.gridInsideSecondText {
    margin-top: 118px;
}

.gridInsideTextThird {
    margin-top: 69px;
}

.bottomStyleText {
    margin-top: 182px;
}

.aboutLineImgStyle {
    margin-top: -5% !important;
    height: 600px;
    margin-left: 10%;
}

.cardCarouselAbout {
    margin-top: 64px;
    background: #FCFCFC;
    height: 120px;
    padding-top: 1%;
    border-top: 2px solid #f0ecec;
}

.aboutDubleImg {
    margin-top: 234px;
    margin-left: 70%;
    margin-bottom: -20px;
}

@media screen and (max-width: 480px) {
    .aboutImgSection {
        height: auto;
    }

    .cardCarouselAbout {
        height: auto;
    }

    .aboutImgSectionText {
        margin-left: unset;
    }

    .aboutLineImgStyle {
        display: none;
    }
}

@media (max-width: 768px) {
    .aboutLineImgStyle {
        display: none;
    }
}

@media (min-width: 820px) and (max-width: 1180px) {
    .aboutLineImgStyle {
        display: none;
    }
}

@media (min-width: 280px) and (max-width: 653px) {
    .aboutDubleImg {
        margin-left: 50%;
    }
}